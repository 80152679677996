import React, { useEffect, useState } from "react";
import Footer from "../components/footer";
import NavBar from "../components/navBar";
import Sidebar from "../components/sidebar";
import About from "./About";
import Contact from "./Contact";
import Home from "./Home";
import { useTranslation } from "react-i18next";
import "animate.css";
import "./style.css";
// import Projects from "./Projects";

export default function Index() {
  // <NAVBAR>

  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const [isDarkMode, setIsDarkMode] = useState(
    () => window.matchMedia("(prefers-color-scheme: dark)").matches
  );

  const toggleDarkMode = () => {
    setIsDarkMode((prev) => !prev);
  };

  useEffect(() => {
    const html = window.document.documentElement;

    const prevTheme = isDarkMode ? "light" : "dark";
    html.classList.remove(prevTheme);

    const nextTheme = isDarkMode ? "dark" : "light";
    html.classList.add(nextTheme);

    localStorage.setItem("theme", nextTheme);
  }, [isDarkMode]);

  const [open, setOpen] = useState(false);

  // </NAVBAR>

  return (
    <>
      <NavBar
        open={open}
        toggleDarkMode={toggleDarkMode}
        setOpen={setOpen}
        isDarkMode={isDarkMode}
        changeLanguage={changeLanguage}
        t={t}
      />
      <Sidebar t={t} />
      <Home isDarkMode={isDarkMode} t={t} />
      <About t={t} />
      {/* <Projects t={t} isDarkMode={isDarkMode} /> */}
      <Contact t={t} />
      <Footer isDarkMode={isDarkMode} />
    </>
  );
}
