import React from "react";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Index from "./pages/Index";
import Error from "./pages/Error";

// Create a router instance with the future flag enabled
const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <Index />,
    },
    {
      path: "*",
      element: <Error />,
    },
  ],
  {
    future: {
      v7_startTransition: true, // Enable the future flag
    },
  }
);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
