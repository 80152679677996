import React from "react";
import { BsEmojiFrown } from "react-icons/bs";
import logob from "../components/imgs/logob.png";

export default function Error() {
  return (
    <div className="h-[100vh]">
      <div className="logo flex justify-center">
        <a href="/">
          <img
            src={logob}
            alt="logo"
            className="m-10 w-20 md:w-20 hover:rotate-[360deg] transition duration-1000"
          />
        </a>
      </div>

      <div className="text-brightBlack dark:text-white mt-12">
        <div className="flex justify-center">
          <BsEmojiFrown className="text-[25vh] text-[#777777]" />
        </div>
        <div>
          <h1 className="flex justify-center text-4xl pt-16 text-[#717270] text-center">
            OOPS, Page Not Found
          </h1>
          <h2 className="flex justify-center text-[#727272] pt-1 text-center">
            The page you are looking for doesn't exist or an other error
            occurred.
          </h2>
          <h2 className="flex justify-center text-[#353535] font-bold pt-10">
            <a href="/" className="text-[1.3em]">
              Back to Homepage?
            </a>
          </h2>
        </div>
      </div>
    </div>
  );
}
