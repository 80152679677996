import React from "react";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { BsTwitter, BsGithub } from "react-icons/bs";
import { motion } from "framer-motion";

export default function Sidebar({ t }) {
  return (
    <motion.div
      initial={{ opacity: 0, x: "-20vw" }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 1, delay: 0.5 }}
      className="socialMedia text-[#0e161c] dark:text-lightGrey lg:w-50 absolute lg:mt-44 z-10 animate__animated animate__fadeIn animate__slow"
    >
      <div className="socials flex flex-col items-center text-[19px] md:pl-10 gap-4 w-100">
        <a
          href="https://facebook.com/lirimav"
          target="_blank"
          rel="noopener noreferrer"
          className="w-5 hover:text-[#4867aa] transition"
        >
          <FaFacebookF />
        </a>
        <a
          href="https://www.twitter.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="w-5 hover:text-[#1da1f2] transition"
        >
          <BsTwitter />
        </a>
        <a
          href="https://www.linkedin.com/in/lirimavdyli/"
          target="_blank"
          rel="noopener noreferrer"
          className="w-5 hover:text-[#0a66c2] transition"
        >
          <FaLinkedinIn />
        </a>
        <a
          href="https://github.com/lirimav"
          target="_blank"
          rel="noopener noreferrer"
          className="w-5 dark:hover:text-[#babbbd] hover:text-[#484848] transition"
        >
          <BsGithub />
        </a>
        <div className="border-solid border-0 border-l h-12 border-[#0e161c] w-0 dark:border-lightGrey"></div>
        <p className="text-[#0e161c] text-base rotate-[-90deg] mt-8 font-bold dark:text-lightGrey">
          {t("home_follow")}
        </p>
      </div>
    </motion.div>
  );
}
