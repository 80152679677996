import React from "react";
import { FaHtml5, FaCss3Alt, FaReact, FaNodeJs } from "react-icons/fa";
import {
  SiTailwindcss,
  SiFramer,
  SiJavascript,
  SiTypescript,
} from "react-icons/si";
import cSharp from "../components/imgs/C.png";
import { motion } from "framer-motion";

const onScrollView = {
  hidden: {
    y: "20vh",
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: { duration: 1, staggerChildren: 0.5 },
  },
};

export default function About({ t }) {
  function scrollC() {
    document.getElementById("contact").scrollIntoView({ behavior: "smooth" });
  }

  return (
    <>
      <div className="text-[#0e161c] dark:text-white mb-44">
        <h1
          id="about"
          className="flex items-center text-center justify-center lg:justify-start lg:text-start text-[1.5em] font-semibold  lg:pl-36"
        >
          {t("about_header")}
        </h1>
        <div className="h-20"></div>

        <div className="flex justify-evenly">
          <motion.div
            variants={onScrollView}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className="w-[50%] text-sm lg:text-base text-gray-900 dark:text-gray-200"
          >
            <motion.p variants={onScrollView}>
              {t("about_text1")}{" "}
              <motion.span variants={onScrollView}>
                {t("about_text3")}
              </motion.span>
            </motion.p>
            <br></br>
            <motion.p variants={onScrollView}>{t("about_text4")}</motion.p>
            <br></br>
            <motion.p variants={onScrollView}>
              {t("about_text5")}{" "}
              <span onClick={scrollC} className="cursor-pointer text-[#4e9dff]">
                {t("about_text5_contact")}
              </span>
              .
            </motion.p>
            <br></br>
          </motion.div>
          <motion.div
            variants={onScrollView}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className="w-24 min-h-[550px] text-[3em] flex flex-col items-center justify-around shadow-lightM dark:shadow-darkM"
          >
            <div>
              <FaHtml5 className="text-[#e44d26]" />
            </div>
            <div>
              <FaCss3Alt className="text-[#087fd3]" />
            </div>
            <div>
              <SiJavascript className="text-[#ebd43a]" />
            </div>
            <div>
              <SiTypescript className="text-[#4a8cd3]" />
            </div>
            <motion.div
              animate={{ rotate: 180 }}
              transition={{ repeat: Infinity, ease: "linear", duration: 4 }}
              className="flex justify-center items-center"
            >
              <FaReact className="text-[#61DBFB]" />
            </motion.div>
            <div>
              <FaNodeJs className="text-[#3c873a]" />
            </div>
            <div>
              <img src={cSharp} alt="c#" className="w-12" />
            </div>
            <div>
              <SiTailwindcss className="text-[#07b0ce]" />
            </div>
            <div>
              <SiFramer className="text-[#3762f1]" />
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
}
